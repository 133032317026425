import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Drawer, Form, Input, message, Space } from 'antd'

import { emailValidate, formAutoTrimOnBlur } from '@/utils/utils'
import { apiAddSupplier, apiEditSupplier } from './apis'
import { ISupplierRecord } from './interface'
import { popupSlot } from './utils'

interface ISupplierDrawerProps {
  /** 传入则表示编辑 */
  record?: ISupplierRecord
  /** 操作成功回调 */
  onOperationSuccess?: (values: Omit<ISupplierRecord, 'sysSupplierId'>) => void
}

const updatePopup = popupSlot.insert(null)

export const SupplierDrawer = Object.assign(
  ({ record, onOperationSuccess, destroy }: ISupplierDrawerProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [loading, setLoading] = useState(false)

    const initialValues = {
      supplierName: record?.supplierName || '',
      contactName: record?.contactName || '',
      contactEmail: record?.contactEmail || '',
      contactPhone: record?.contactPhone || '',
      address: record?.address || '',
    }

    const [form] = Form.useForm<typeof initialValues>()

    return (
      <Drawer
        title={record ? t('Supplier.bian-ji-gong-ying-shang') : t('Supplier.xin-zeng-gong-ying-shang')}
        width={700}
        extra={
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={async () => {
                let values = await form.validateFields()
                values = form.getFieldsValue(true)
                try {
                  setLoading(true)
                  if (record) await apiEditSupplier(record.sysSupplierId, values)
                  else await apiAddSupplier(values)
                  onOperationSuccess?.(values)
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  onHide()
                } catch (err) {
                  setLoading(false)
                  throw err
                }
              }}
            >
              {record ? t('2-common.bao-cun') : t('2-common.xin-zeng')}
            </Button>
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 - 6 - 1 }}
          initialValues={initialValues}
          onBlur={e => {
            formAutoTrimOnBlur({
              event: e,
              form,
              includeField: Object.keys(initialValues),
            })
          }}
        >
          <Form.Item label={t('Supplier.gong-ying-shang-ming-cheng')} name="supplierName" rules={[{ required: true }]}>
            <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
          <Form.Item label={t('Supplier.lian-xi-ren-xing-ming')} name="contactName">
            <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
          <Form.Item
            label={t('Supplier.lian-xi-ren-you-xiang')}
            name="contactEmail"
            rules={[
              { required: true },
              {
                pattern: emailValidate.emailReg,
                message: t('2-common.you-xiang-ge-shi-bu-zheng-que'),
              },
            ]}
          >
            <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
          <Form.Item label={t('Supplier.lian-xi-ren-dian-hua')} name="contactPhone" rules={[{ required: true }]}>
            <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
          <Form.Item label={t('2-common.di-zhi')} name="address">
            <Input.TextArea placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
        </Form>
      </Drawer>
    )
  },
  {
    open: (props: ISupplierDrawerProps) => {
      updatePopup(<SupplierDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
