import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { ISupplierRecord } from './interface'
import { SupplierDrawer } from './SupplierDrawer'
import { useSupplierList } from './useSupplierList'
import { popupSlot } from './utils'
import styles from './styles.module.less'

export const Supplier = () => {
  const { t } = useTranslation()
  const { loading, data, getList, changeRecord } = useSupplierList()

  const columns: ColumnsType<ISupplierRecord> = [
    {
      title: t('Supplier.gong-ying-shang-ming-cheng'),
      render: (_v, { supplierName: v }) => v,
    },
    {
      title: t('Supplier.lian-xi-ren-xing-ming'),
      render: (_v, { contactName: v }) => v,
    },
    {
      title: t('Supplier.lian-xi-ren-you-xiang'),
      render: (_v, { contactEmail: v }) => v,
    },
    {
      title: t('Supplier.lian-xi-ren-dian-hua'),
      render: (_v, { contactPhone: v }) => v,
    },
    {
      title: t('2-common.di-zhi'),
      render: (_v, { address: v }) => v,
    },
    {
      title: t('2-common.cao-zuo'),
      width: 90,
      render: (_v, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              SupplierDrawer.open({
                record,
                onOperationSuccess: values => changeRecord(record.sysSupplierId, values),
              })
            }}
          >
            {t('2-common.bian-ji')}
          </Button>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={async () => {
            SupplierDrawer.open({
              onOperationSuccess: () => getList(),
            })
          }}
        >
          {t('Supplier.xin-zeng-gong-ying-shang')}
        </Button>
      </div>
      <div className={styles.main}>
        <CusTable
          sticky
          rowKey="sysSupplierId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data}
          columns={columns}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
