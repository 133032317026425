import { CSSProperties, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn } from 'ahooks'
import { Select, SelectProps, theme } from 'antd'

import { Loading } from '@/components/Loading'
import { ISupplierRecord, useSupplierList } from '../Supplier'

export type { ISupplierRecord }

/** 供应商选择器 */
export const SupplierSelect = function <T extends boolean = false>({
  style,
  className,
  multiple,
  maxTagCount,
  maxTagTextLength,
  placeholder,
  merchantUserId,
  value,
  onChange,
  onList,
  ...rest
}: {
  style?: CSSProperties
  className?: string
  multiple?: T
  /** @default 1 */
  maxTagCount?: SelectProps['maxTagCount']
  /** @default 5 */
  maxTagTextLength?: SelectProps['maxTagTextLength']
  placeholder?: ReactNode
  disabled?: boolean
  /** @default true */
  allowClear?: boolean
  /** 商家用户id，只认初始值 */
  merchantUserId?: string
  value?: T extends true ? string[] : string
  onChange?: (value: T extends true ? string[] : string | undefined) => void
  onList?: (list: ISupplierRecord[]) => void
}) {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const handleOnList = useMemoizedFn((list: ISupplierRecord[]) => onList?.(list))

  const { loading, data } = useSupplierList({ merchantUserId })

  useEffect(() => {
    if (data) handleOnList(data)
  }, [data, handleOnList])

  return (
    <Select
      allowClear
      {...rest}
      style={{ minWidth: 110, maxWidth: !multiple ? 200 : undefined, ...style }}
      className={className}
      showSearch={false}
      placeholder={
        placeholder ||
        (multiple ? (
          <span style={{ color: token.colorTextBase }}>{t('6-comp.quan-bu-gong-ying-shang')}</span>
        ) : (
          t('6-comp.qing-xuan-ze-gong-ying-shang')
        ))
      }
      mode={multiple ? 'multiple' : undefined}
      maxTagCount={maxTagCount || 1}
      maxTagTextLength={maxTagTextLength || 5}
      popupMatchSelectWidth={false}
      notFoundContent={loading ? <Loading delay={0} /> : undefined}
      options={data?.map(d => ({
        value: d.sysSupplierId,
        label: d.supplierName,
      }))}
      value={value || undefined}
      onChange={val => {
        onChange?.(val)
      }}
    />
  )
}
