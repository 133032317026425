import { useRef } from 'react'
import { useRequest } from 'ahooks'
import { produce } from 'immer'

import { apiGetRecords } from './apis'
import { ISupplierRecord } from './interface'

/** 获取供应商列表 */
export const useSupplierList = ({
  manual,
  merchantUserId,
}: {
  /** 是否手动调用 getList */
  manual?: boolean
  /** 商家用户id，只认初始值 */
  merchantUserId?: string
} = {}) => {
  merchantUserId = useRef(merchantUserId).current

  const {
    loading,
    data,
    run: getList,
    mutate,
  } = useRequest<ISupplierRecord[], []>(() => apiGetRecords(merchantUserId), {
    cacheKey: `Supplier/useSupplierList@${merchantUserId || 'curUser'}`,
    cacheTime: -1,
    staleTime: 1000 * 1, // 该时间内重复调用时不请求，直接认为缓存是新鲜的
    manual,
    onError: () => void mutate(undefined),
  })

  const changeRecord = (sysSupplierId: string, changedValues: Partial<ISupplierRecord>) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        for (const item of draft) {
          if (item.sysSupplierId === sysSupplierId) {
            Object.assign(item, changedValues)
            return
          }
        }
      })
    })
  }

  return { loading, data, getList, changeRecord }
}
