import axios from 'axios'

import { ISupplierRecord } from './interface'
import './__mock__'

/** 获取供应商列表 */
export const apiGetRecords = async (merchantUserId?: string): Promise<ISupplierRecord[]> => {
  let url = '/linkhub_oms/supplier/list'
  if (merchantUserId) url += `/${merchantUserId}`
  return axios.get(url).then(res => res.$data || [])
}

/** 新增供应商 */
export const apiAddSupplier = async (reqData: Omit<ISupplierRecord, 'sysSupplierId'>) => {
  await axios.post('/linkhub_oms/supplier', reqData)
}

/** 编辑供应商 */
export const apiEditSupplier = async (sysSupplierId: string, reqData: Omit<ISupplierRecord, 'sysSupplierId'>) => {
  await axios.put(`/linkhub_oms/supplier/${sysSupplierId}`, reqData)
}
